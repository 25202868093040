import { element } from "prop-types";
import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Report = Loadable(lazy(() => import("src/pages/reports/index")));

const FundReport = Loadable(lazy(() => import("src/pages/reports/fund/index")));

const PayoutReport = Loadable(
    lazy(() => import("src/pages/reports/payout/index"))
);

const PointReport = Loadable(
    lazy(() => import("src/pages/reports/point/index"))
);
const SalesReport = Loadable(
    lazy(() => import("src/pages/reports/sales/index"))
);
const JoiningReport = Loadable(
    lazy(() => import("src/pages/reports/joining/index"))
);

const IncomeReport = Loadable(
    lazy(() => import("src/pages/reports/income/index"))
);

const TopEarnersReport = Loadable(
    lazy(() => import("src/pages/reports/topEarners/index"))
);
const Transaction = Loadable(
    lazy(() => import("src/pages/reports/transaction/index"))
);
const GiftedProduct = Loadable(
    lazy(() => import("src/pages/reports/gift/index"))
);
const TopPurchasedUsers = Loadable(
    lazy(() => import("src/pages/reports/top-purchased-users"))
);
const TopPurchaseUserDetails = Loadable(
    lazy(() =>
        import("src/pages/reports/top-purchased-users/top-purchase-user/index")
    )
);

const reports = [
    {
        path: "report",
        element: <Report />,
        children: [
            {
                element: <Navigate to='fund/credit' />,
                index: true,
            },
            {
                path: "fund/:subPage",
                element: (
                    <FundReport
                        title='Fund Credit Report'
                        heading='Fund Credit'
                        type='fundCredit'
                    />
                ),
            },
            {
                path: "joining",
                element: (
                    <JoiningReport
                        title='Joining Report'
                        heading='Joining Report'
                        type='joining'
                    />
                ),
            },
            {
                path: "income",
                element: (
                    <IncomeReport
                        title='Member Income Report'
                        heading='Member Income Report'
                        type='income'
                    />
                ),
            },
            {
                path: "payout",
                element: (
                    <PayoutReport
                        title='Payout Report'
                        heading='Payout Report'
                        type='payout'
                    />
                ),
            },

            {
                path: "point/history",
                element: (
                    <PointReport
                        title='Point History Report'
                        heading='Point History Report'
                        type='point'
                    />
                ),
            },
            {
                path: "earners",
                element: (
                    <TopEarnersReport
                        title='Top Earners Report'
                        heading='Top Earners Report'
                        type='earners'
                    />
                ),
            },
            {
                path: "transaction",
                element: (
                    <Transaction
                        title='Transaction'
                        heading='Transaction'
                        type='transaction'
                    />
                ),
            },
            {
                path: "sales",
                element: (
                    <SalesReport
                        title='Sales Report'
                        heading='Sales Report'
                        type='sales'
                    />
                ),
            },
            {
                path: "top-purchasers",
                children: [
                    {
                        index: true,
                        element: (
                            <TopPurchasedUsers
                                title='Top Purchasers'
                                heading='Top Purchasers'
                                type='topPurchase'
                            />
                        ),
                    },
                    {
                        path: ":uid",
                        element: (
                            <TopPurchaseUserDetails
                                title='Top Purchase'
                                heading='Top Purchase'
                                type='noType'
                            />
                        ),
                    },
                ],
            },
            {
                path: "gift",
                element: (
                    <GiftedProduct
                        title='Gifted Products'
                        heading='Gifted Products'
                        type='gift'
                    />
                ),
            },
        ],
    },
];

export default reports;
